/* eslint-disable react/no-danger */
import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  FormControl,
  FormLabel,
  Link,
  Typography,
} from '@mui/material';
import {
  AccountBalance,
  Place,
  AccountCircle,
  Event,
  CorporateFare,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import ExpandableTagList from 'generic/components/ui/ExpandableTagList';
import { overrideRessource } from 'generic/utils/utils';
import ImgLoader from 'generic/components/ui/ImgLoader';
import ImageNotFound from 'generic/components/ui/ImageNotFound';
import { qesdocumentPropType } from 'custom/ragoon/core/qes/documentProptypes';
import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);

const useStyles = makeStyles((theme) => ({
  itemImage: {
    objectFit: 'cover',
    display: 'block',
    borderRadius: '4px',
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: theme.palette.divider,
  },
}));

const Document = ({
  document,
  displayTitle,
  isCompleteDisplay,
  disableTagsRefine,
  handleGetDocumentComplete,
}) => {
  const currentTheme = useColorSchemeDetector();
  const { relationsEntitiesColors } = currentTheme.HIGHCHARTS;

  const [isEnglishExtractCollapsed, setIsEnglishExtractCollapsed] = useState(true);
  const [showCollapsibleToggle, setShowCollapsibleToggle] = useState(false);
  const ref = useRef();
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    snippet, text, title, fileExtension, fileLang, fileName, attachment,
    attachmentTranslated, attachmentTranslatedUrl, originalText, thumb, sentences,
  } = useDocumentFields(document);
  const { qesdocument } = document;

  const titleLinkProps = {
    component: 'span',
    onClick: handleGetDocumentComplete,
  };
  const shownExtract = isEnglishExtractCollapsed ? snippet : text;

  useEffect(() => {
    if (ref.current && ref.current.clientHeight > 80) {
      setShowCollapsibleToggle(true);
    }
  }, [ref]);
  return (
    <Box display="block" width="100%" sx={{ wordBreak: 'break-word' }}>
      {displayTitle && (
        <Typography
          variant="h6"
          component="span"
          fontSize="1.2rem"
          margin="6px 0"
          display="block"
        >
          <Box
            {...titleLinkProps}
            color="text.primary"
            sx={{ cursor: 'pointer' }}
          >
            {title}
          </Box>
        </Typography>
      )}

      {thumb && (
        <Box mr={1} mb={1} sx={{ float: 'left' }}>
          <ImgLoader
            src={thumb}
            fallbackComponent={(
              <ImageNotFound
                height={120}
                width={140}
              />
            )}
            className={classes.itemImage}
            skeletonClassName={classes.itemImage}
            height={120}
            width={140}
            loading="lazy"
          />
        </Box>
      )}
      <Box>
        <b>{t('doc.original_file')}</b>
        <Link
          href={attachment}
          rel="noopener"
          target="_blank"
          underline="none"
        >
          <Typography
            component="span"
            variant="body2"
          >
            {fileName}
          </Typography>
        </Link>
      </Box>
      {attachmentTranslated && (
        <Box>
          <b>{t('doc.translated_file')}</b>
          <Link
            href={attachmentTranslatedUrl}
            rel="noopener"
            target="_blank"
            underline="none"
          >
            <Typography
              component="span"
              variant="body2"
            >
              {attachmentTranslated}
            </Typography>
          </Link>
        </Box>
      )}

      {fileExtension && (
        <Box>
          <b>{t('doc.file_extension')}</b>
          {fileExtension}
        </Box>
      )}

      {fileLang && (
        <Box>
          <b>{t('doc.file_lang')}</b>
          {t(`dashboard.languages.${fileLang}`)}
        </Box>
      )}

      {sentences && (
        sentences.map((sentence) => (
          <Box
            p={1}
            mt={0.5}
            mb={1}
            borderLeft="5px solid #d3d3d3"
            whiteSpace="pre-wrap"
            lineHeight="22px"
            sx={{ clear: 'both' }}
          >
            {sentence}
          </Box>
        ))
      )}

      {!isCompleteDisplay && shownExtract && (
        <Box border="1px solid #dedede" sx={{ clear: 'both' }} mb={1}>
          <Box fontWeight="bold" p={0.5} display="flex" justifyContent="space-between">
            {t('doc.english_version')}
            {showCollapsibleToggle && !isEnglishExtractCollapsed && (
              <Box
                sx={{ cursor: 'pointer' }}
                title={t('ui.collapse')}
                onClick={() => setIsEnglishExtractCollapsed(true)}
              >
                <KeyboardDoubleArrowUp
                  color="primary"
                />
              </Box>
            )}
          </Box>
          <Collapse collapsedSize={80} in={!isEnglishExtractCollapsed}>
            <Box ref={ref} p={0.5} maxHeight="600px" overflow="auto">
              {fileExtension === 'md' ? (
                <Box width="100%" sx={{ '& td': { width: '25%' } }}>
                  <div dangerouslySetInnerHTML={{ __html: shownExtract }} />
                </Box>
              ) : (
                <Box width="100%" whiteSpace="pre-wrap">
                  {shownExtract}
                </Box>
              )}
            </Box>
          </Collapse>
          {showCollapsibleToggle && (
            <Box
              width="100%"
              sx={{ cursor: 'pointer' }}
              onClick={() => setIsEnglishExtractCollapsed(!isEnglishExtractCollapsed)}
              borderTop="1px solid #dedede"
              title={t(`ui.${isEnglishExtractCollapsed ? 'expand' : 'collapse'}`)}
            >
              <Box
                display="flex"
                justifyContent="center"
                fontSize="34px"
                backgroundColor="secondary.transparent"
              >
                {isEnglishExtractCollapsed ? (
                  <KeyboardDoubleArrowDown color="primary" />
                ) : (
                  <KeyboardDoubleArrowUp color="primary" />
                )}
              </Box>
            </Box>
          )}
        </Box>
      )}
      <Box mt={0.5}>
        <ExpandableTagList
          disableRefine={disableTagsRefine}
          qesdocument={qesdocument}
          tags={[
            { fieldName: 'QES_Company', icon: AccountBalance },
            { fieldName: 'QES_Organization', icon: CorporateFare },
            { fieldName: 'QES_Concept' },
            { fieldName: 'QES_Person', icon: AccountCircle },
            { fieldName: 'QES_Event', icon: Event },
            { fieldName: 'QES_Location', icon: Place },
            { fieldName: 'QES_ConceptRisk' },
          ].map((field, index) => ({
            ...field,
            color: relationsEntitiesColors[index],
          }))}
        />
      </Box>
      {isCompleteDisplay && (
        <Fragment>
          <div>
            <b>{t('doc.original_text')}</b>
            {originalText}
          </div>
          <FormControl
            component="fieldset"
            className="formFieldset"
            sx={{ marginTop: 3 }}
            fullWidth
          >
            <FormLabel
              component="legend"
            >
              {t('doc.english_version')}
            </FormLabel>
            <Box p={2} mt={1} mb={2} whiteSpace="pre-wrap" sx={{ clear: 'both' }}>
              {text}
            </Box>
          </FormControl>
        </Fragment>
      )}
    </Box>
  );
};

Document.propTypes = {
  disableTagsRefine: PropTypes.bool,
  displayTitle: PropTypes.bool,
  // description du document QES attendu pour ce client
  document: qesdocumentPropType,
  handleGetDocumentComplete: PropTypes.func,
  isCompleteDisplay: PropTypes.bool,
};

Document.defaultProps = {
  disableTagsRefine: false,
  displayTitle: true,
  document: {},
  handleGetDocumentComplete: null,
  isCompleteDisplay: false,
};

export default Document;
