import PropTypes from 'prop-types';
import { stringOrArrayOfStrings } from 'generic/utils/propTypeUtils';

const qesdocumentPropType = PropTypes.shape({
  BASE_ID: stringOrArrayOfStrings.isRequired,
  file_creation_date: stringOrArrayOfStrings.isRequired,
  PJ_Texte: stringOrArrayOfStrings.isRequired,
  PJ_Fichier_joint: stringOrArrayOfStrings.isRequired,
  PJ_Fichier_joint_co: stringOrArrayOfStrings.isRequired,
  Phrase: stringOrArrayOfStrings.isRequired,
  PJ_Extension: stringOrArrayOfStrings.isRequired,
  PJ_Nom_fichier: stringOrArrayOfStrings.isRequired,
  PJ_Langue: stringOrArrayOfStrings.isRequired,
});

const documentPropType = PropTypes.shape({
  qesdocument: qesdocumentPropType,
});

export {
  documentPropType,
  qesdocumentPropType,
};
